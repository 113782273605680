.register-card .register-form {
    max-width: 300px;
    margin: auto;
}

.register-card .register-form-forgot {
    float: right;
}

.register-card .register-form-button {
    width: 100%;
}

.register-bg{
    margin: 50px;
    min-height: 1000px;
    /* height: 80%; */
}

.register-card{
    /* width: 100%; */
    max-width: 500px;
    margin: auto;
}