body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root > div{
  min-height: 100%;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 64px 10px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: rgba(0, 21, 41, 0.95);
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #1890ff;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #001529;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.header0-logo > a {
  color: white;
  font-size: larger;
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url('http://cloud.gutemorgan.com:18888/ads/huanghe/bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 700px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 500px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content0-wrapper {
  height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0 .block-wrapper .block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  height: 200px;
  margin-bottom: 48px;
}
.content0-wrapper .content0 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0 .block-wrapper .block .icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
}
.content0-wrapper .content0 .block-wrapper .block > h3 {
  line-height: 32px;
  margin: 10px auto;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 880px;
  }
}
.content7-wrapper {
  min-height: 720px;
}
.content7-wrapper .content7 > h1,
.content7-wrapper .content7 > p {
  text-align: center;
}
.content7-wrapper .content7-tag i {
  width: 12px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.content7-wrapper .content7-tag-name {
  display: inline-block;
}
.content7-wrapper .content7 .ant-tabs-bar {
  text-align: center;
}
.content7-wrapper .content7 .ant-tabs .ant-tabs-nav {
  float: none;
  text-align: center;
}
.content7-wrapper .content7-tabs-wrapper {
  position: relative;
  margin-top: 24px;
}
.content7-wrapper .content7-content {
  display: flex;
  align-items: center;
}
.content7-wrapper .content7-text {
  padding: 24px 48px;
}
.content7-wrapper .content7-img {
  padding: 24px 48px;
}
.content7-wrapper .content7 .ant-tabs-tabpane {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    min-height: 980px;
    overflow: hidden;
  }
  .content7-wrapper .content7 {
    max-width: 100%;
  }
  .content7-wrapper .content7-content {
    display: block;
  }
  .content7-wrapper .content7-text,
  .content7-wrapper .content7-img {
    text-align: left;
    padding: 0;
  }
  .content7-wrapper .content7-img {
    margin-top: 32px;
  }
  .content7-wrapper .content7 .ant-tabs-bar {
    width: auto;
  }
  .content7-wrapper .content7 .ant-tabs-bar .ant-tabs-nav {
    float: left;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.content12-wrapper {
  background-color: #fafafa;
  min-height: 470px;
}
.content12-wrapper .content12 {
  padding: 64px 24px;
}
.content12-wrapper .content12 > p {
  text-align: center;
}
.content12-wrapper .img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content12-wrapper .img-wrapper .block {
  margin-bottom: 40px;
}
.content12-wrapper .img-wrapper .block .block-content {
  display: flex;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: none;
  height: 64px;
  align-items: center;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content12-wrapper .img-wrapper .block .block-content > span {
  width: 100%;
  display: block;
}
@media screen and (max-width: 767px) {
  .content12-wrapper {
    overflow: hidden;
  }
  .content12-wrapper .content12 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content12-wrapper .content12 ul li span {
    height: 168px;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
.banner3 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/xTPkCNNLOnTEbGgVZOpE.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  font-size: 68px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 600;
  margin: 26px auto 38px;
  overflow: hidden;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  display: block;
  margin: 72px auto 0;
  background: #034365;
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
}
.content4-wrapper {
  background: #fafafa;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4-video {
    top: 15%;
    background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
    background-size: cover;
  }
}
.content4-title-content {
  max-width: 800px;
  margin: auto;
  text-align: justify;
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
  padding-top: 120px;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content2-wrapper {
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
  padding-top: 120px;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #1890ff;
}
.feature6-title-bar {
  height: 6px;
  background: #1890ff;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #1890ff;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #1890ff;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(24, 144, 255, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #1890ff;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
div > .templates-wrapper > .jnwq7vhwgqg-editor_css {
  background-image: none;
}

.search-bg {
    margin: auto;
    width: 90%;
    max-width: 1200px;
}

.search-searchbar-row {
    text-align: center;
    width: 100%;
    margin: auto;
    max-width: 600px;
}

.search-searchbar {
    width: 100%;
    /* min-width: 600px; */
    margin: auto;
}

.search-result {
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 1200px;
}

.search-title {
    text-align: center;
    margin: 50px 0px;
    font-size: 50px;
    font-weight: bolder;
    display: block;
}

/* .search-item {
    color: rgba(0, 0, 0, 0.65);
    background-color: aqua;
}

.search-item:hover {
    box-shadow: 0 0 4px #eee;
} */

.search-result-card-meta>.ant-card-meta-detail>.ant-card-meta-title {
    /* background-color: aqua; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: small;
    min-height: 40px;
}

.search-result-card {
    height: 260px;
    /* max-width: 270px; */
    background-color: #f6f6f6;
}

.search-result-card :hover {
    color: #40a9ff;
}

.search-result-card-pic {
    height: 150px;
    border: 10px solid #f6f6f6;

    /* width: 200px; */
}

/* .detail-card-content{
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
}

.detail-section{
    margin-top: 50px;
    font-size: x-large;
}
.detail-section-title{
    text-align: left;
    font-size: xx-large;
    font-weight: bolder;
}
.detail-section-content{
    text-align: left;
    margin: 10px 0px;
} */
.detail-background {
    min-height: 100%;
    background-color: aqua;
    padding-top: 50px;
    background-color: #f6f6f6;
    padding-bottom: 50px;

}

.detail-keyword{
    line-height: 30px;
}

.detail-bg {
    margin: auto;
    width: 80%;
    max-width: 1200px;
    /* margin-top: 50px; */
}

.detail-title {
    margin-bottom: 50px;
}

.detail-section {
    margin-bottom: 15px;
    display: block;
}

.detail-section-title {
    background-color: chocolate;
}

.detail-button-row {
    margin-bottom: 10px;
}

.detail-button-row > .ant-btn {
    width: 100%;
}

.detail-card{
    margin-top: 20px;
}

.detail-card-second{
    width: 100%;
}
.observe-background {
    min-height: 100%;
    max-width: 1000px;
    width: 90%;
    margin: auto;
    padding-bottom: 80px;
}

.observe-project-row {
    width: 100%;
    padding-top: 20px;
}

.observe-project-title-row {
    padding-top: 80px;
    text-align: center;
}

.observe-project-divider{
    padding-top: 50px;
}

.observe-project-title-h1{
    font-size: 30px;
}

.observe-project-description{
    font-size: large;
}




.observe-card {
    /* height: 260px; */
    /* max-width: 270px; */
    /* background-color: #f6f6f6; */
    margin-top: 60px;
}

.observe-card-meta>.ant-card-meta-detail>.ant-card-meta-title {
    /* background-color: aqua; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: small;
    min-height: 40px;
}
.project-background {
    min-height: 100%;
    background-color: aqua;
    background-color: #f6f6f6;
    padding-bottom: 50px;

}

.project-row {
    width: 100%;
}

.project-row-margin {
    margin-top: 50px;
}

.project-banner {
    background: url("https://heihe.tpdc.ac.cn/static/static-heihe/img/observe-hiwater/test1.jpg") no-repeat center;
    background-size: cover;
    height: 220px;
    text-align: center;
    padding-top: 50px;
}

.project-banner-content {
    max-width: 800px;
    margin: auto;
    text-align: left;

}

.project-banner-content>h1 {
    margin-bottom: 30px;
    font-size: 50px;
}

.project-banner-content>p {
    margin: 0;
}


.project-content {
    margin: auto;
    margin-top: 50px;
    text-align: center;
    max-width: 800px;
}

.project-tab{
    margin-top: 50px;
}

.project-tab>.ant-tabs-content-holder {
    text-align: left;
    white-space: pre-line;
    min-height: 200px;
}

.project-title{
    margin-top: 50px;
}

.project-partner-list .ant-card-meta-description{
    color: black;
}
.login-card .login-form {
    max-width: 300px;
    margin: auto;
}

.login-card .login-form-forgot {
    float: right;
}

.login-card .login-form-button {
    width: 100%;
}

.login-bg{
    margin: 50px;
    min-height: 1000px;
}

.login-card{
    /* width: 50%; */
    max-width: 500px;
    margin: auto;
}
.register-card .register-form {
    max-width: 300px;
    margin: auto;
}

.register-card .register-form-forgot {
    float: right;
}

.register-card .register-form-button {
    width: 100%;
}

.register-bg{
    margin: 50px;
    min-height: 1000px;
    /* height: 80%; */
}

.register-card{
    /* width: 100%; */
    max-width: 500px;
    margin: auto;
}
.map-option-card {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 100px;
    background-color: rgba(255, 255, 255, 0.6);
}

.map-sample-card {
    width: 250px;
    position: absolute;
    bottom: 50px;
    right: 50px;
    background-color: rgba(255, 255, 255, 0.6);
}

.map-icon-triangle {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid;
    display: inline-block;
    /* 使三角形为行内元素 */
}

.map-icon-circle {
    width: 10px;        /* 圆的直径 */
    height: 10px;       /* 圆的直径 */
    border-radius: 50%;  /* 边框半径设置为50%，使其完全圆滑 */
    display: inline-block;
  }


.map-card-section {}

.map-card-section-title {
    font-size: large;
    font-weight: bold;
}

.map-card-section-item {
    color: black;
}

@media screen and (max-width: 767px) {
    .map-option-card {
        width: 100%;
        position: absolute;
        top: 100px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.6);
    }
}
