.search-bg {
    margin: auto;
    width: 90%;
    max-width: 1200px;
}

.search-searchbar-row {
    text-align: center;
    width: 100%;
    margin: auto;
    max-width: 600px;
}

.search-searchbar {
    width: 100%;
    /* min-width: 600px; */
    margin: auto;
}

.search-result {
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 1200px;
}

.search-title {
    text-align: center;
    margin: 50px 0px;
    font-size: 50px;
    font-weight: bolder;
    display: block;
}

/* .search-item {
    color: rgba(0, 0, 0, 0.65);
    background-color: aqua;
}

.search-item:hover {
    box-shadow: 0 0 4px #eee;
} */

.search-result-card-meta>.ant-card-meta-detail>.ant-card-meta-title {
    /* background-color: aqua; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: small;
    min-height: 40px;
}

.search-result-card {
    height: 260px;
    /* max-width: 270px; */
    background-color: #f6f6f6;
}

.search-result-card :hover {
    color: #40a9ff;
}

.search-result-card-pic {
    height: 150px;
    border: 10px solid #f6f6f6;

    /* width: 200px; */
}

/* .detail-card-content{
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
}

.detail-section{
    margin-top: 50px;
    font-size: x-large;
}
.detail-section-title{
    text-align: left;
    font-size: xx-large;
    font-weight: bolder;
}
.detail-section-content{
    text-align: left;
    margin: 10px 0px;
} */