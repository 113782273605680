.map-option-card {
    width: 400px;
    position: absolute;
    top: 100px;
    left: 100px;
    background-color: rgba(255, 255, 255, 0.6);
}

.map-sample-card {
    width: 250px;
    position: absolute;
    bottom: 50px;
    right: 50px;
    background-color: rgba(255, 255, 255, 0.6);
}

.map-icon-triangle {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid;
    display: inline-block;
    /* 使三角形为行内元素 */
}

.map-icon-circle {
    width: 10px;        /* 圆的直径 */
    height: 10px;       /* 圆的直径 */
    border-radius: 50%;  /* 边框半径设置为50%，使其完全圆滑 */
    display: inline-block;
  }


.map-card-section {}

.map-card-section-title {
    font-size: large;
    font-weight: bold;
}

.map-card-section-item {
    color: black;
}

@media screen and (max-width: 767px) {
    .map-option-card {
        width: 100%;
        position: absolute;
        top: 100px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.6);
    }
}