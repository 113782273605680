.detail-background {
    min-height: 100%;
    background-color: aqua;
    padding-top: 50px;
    background-color: #f6f6f6;
    padding-bottom: 50px;

}

.detail-keyword{
    line-height: 30px;
}

.detail-bg {
    margin: auto;
    width: 80%;
    max-width: 1200px;
    /* margin-top: 50px; */
}

.detail-title {
    margin-bottom: 50px;
}

.detail-section {
    margin-bottom: 15px;
    display: block;
}

.detail-section-title {
    background-color: chocolate;
}

.detail-button-row {
    margin-bottom: 10px;
}

.detail-button-row > .ant-btn {
    width: 100%;
}

.detail-card{
    margin-top: 20px;
}

.detail-card-second{
    width: 100%;
}