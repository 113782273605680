.observe-background {
    min-height: 100%;
    max-width: 1000px;
    width: 90%;
    margin: auto;
    padding-bottom: 80px;
}

.observe-project-row {
    width: 100%;
    padding-top: 20px;
}

.observe-project-title-row {
    padding-top: 80px;
    text-align: center;
}

.observe-project-divider{
    padding-top: 50px;
}

.observe-project-title-h1{
    font-size: 30px;
}

.observe-project-description{
    font-size: large;
}




.observe-card {
    /* height: 260px; */
    /* max-width: 270px; */
    /* background-color: #f6f6f6; */
    margin-top: 60px;
}

.observe-card-meta>.ant-card-meta-detail>.ant-card-meta-title {
    /* background-color: aqua; */
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: small;
    min-height: 40px;
}